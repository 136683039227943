const whatsNewPosts = [
  /*
  {
    date: 'July 2024',
    title: 'Heroics trades the first Bitcoin OTC derivative with Goldman Sachs',
    content:
      'Heroics becomes the first European Hedge Fund to trade Bitcoin with Goldman Sachs Bank Europe, setting a precedent in the cryptocurrency market.',
  },
  */
  {
    date: 'October 2024',
    title: "The Fundamentals of the Structured Products Industry",
    content:
      " <a href='https://www.linkedin.com/posts/fahmi-ben-abdelkader-6111a64_luxembourg-ugcPost-7253125773098643456-AsBI?utm_source=share&utm_medium=member_desktop' target='_blank' rel='noopener noreferrer'>Cyril Nahabedian - CEO & Co-Founder of Heroics Capital Partners - attended an enlightening seminar on structured products with ESCP Business School' students. Click to see more</a>",
  },{
    date: 'July 2024',
    title: 'First european hedge fund to trade a BTC over-the-counter derivative with Goldman Sachs Europe.',
    content:
      "In July 2024, Heroics Global Strategy SICAV-RAIF made a historic milestone by becoming the first European hedge fund to execute an OTC derivative product on Bitcoin with Goldman Sachs Europe. This transaction demonstrates the growing integration of digital assets into institutional investment strategies and paves the way for greater adoption of crypto-asset derivatives. This development highlights Heroics Global Strategy's pioneering role in financial sector innovation. Heroics Capital, the fund's parent company, stands out as a key player specializing in alternative investment strategies and innovative technologies.",
  },{
    date: 'July 2024',
    title: 'Release of Heroics Sentinelle',
    content:
      'In July 2024, Heroics released a risk analytics platform - Heroics Sentinelle - for daily monitoring of positions and risks.',
  },{
    date: 'July 2024',
    title: 'Launching of a new dedicated AMC',
    content:
      'July 2024 saw the introduction of a new Dedicated Actively Managed Certificate of EUR 7 million by Heroics, expanding its investment offering.',
  },
  {
    date: 'May 2024',
    title: 'Launching of a new Dedicated Fund',
    content:
      'May 2024 saw the introduction of a new Dedicated Fund by Heroics, expanding its investment offerings.',
  },
  {
    date: 'May 2024',
    title: 'Opening of the H Dynamic AMC',
    content:
      'In May 2024, Heroics announced the opening of the Dynamic Actively Managed Certificate, which aims to adapt to market changes and provide versatile investment opportunities.',
  },
  {
    date: 'June 2023',
    title: 'Launching of the Heroics Volatility Hedge Fund',
    content:
      'In June 2023, Heroics launched its highly anticipated Hedge Fund, marking a significant milestone in the European financial sector.',
  },

  // Add more posts as needed
];

export default whatsNewPosts;
