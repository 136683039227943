import whatsNewPosts from '../data/news';

function News() {
  return (
    <div className='items-center flex justify-center flex-wrap z-0 w-full'>
      <img
        className='fixed top-0 left-0 w-full -z-50'
        src='img/ny.png'
        alt='street'
      />
      <div className='m-32'>
        <img
          className='top-32 left-0 w-full -z-40 filter grayscale'
          src='img/heroics-logo-rgb blanc.png'
          alt='logo'
        />
      </div>
      <div className='w-full pb-[50px] bg-white'>
        <div className='z-40 text-gray-800 bg-white md:p-10 sm:p-0 flex flex-row justify-center items-center flex-wrap '>
          <div className='w-full flex justify-center items-center text-3xl m-10'>
            <p>Latest News</p>
          </div>
          <div className='lg:w-1/2 p-5 sm:w-full'>
            <div className='max-w-3xl mx-auto mt-8'>
              <h1 className='text-3xl font-bold mb-4'>What's New</h1>
              {whatsNewPosts.map((post, index) => (
                <div key={index} className='mb-16'>
                  <p className='text-gray-500 mb-1'>{post.date}</p>
                  <h2 className='text-xl font-semibold mb-2'>{post.title}</h2>
                  <p className='text-gray-700' dangerouslySetInnerHTML={{ __html: post.content }}
                  ></p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
